<template>
  <div>
    <div class="search">
      <Form :model="form" label-position="top">
        <Row :gutter="30">
          <!-- <Col :md="6" :xs="24">
            <FormItem label="参评单位名称：">
              <Input v-model="form.regName" clearable @on-enter="search"></Input>
            </FormItem>
          </Col> -->
          <!-- <Col :md="6" :xs="24">
            <FormItem label="参评单位类型：">
              <Select v-model="form.applicantType" clearable>
                <Option value="个人">个人</Option>
                <Option value="院校或科研机构">院校或科研机构</Option>
                <Option value="企业">企业</Option>
              </Select>
            </FormItem>
          </Col> -->
          <!-- <Col :md="6" :xs="24">
            <FormItem label="推荐单位：">
              <Select v-model="form.pushCompany" clearable :disabled="isDisabled">
                <Option :value="p.value" v-for="p in getPushCompany()" :key='p.id'>{{p.label}}</Option>
                <Option value="其他">其他</Option>
              </Select>
            </FormItem>
          </Col> -->
          <Col :md="6" :xs="24">
            <FormItem label="参评项目名称：">
              <Input v-model="form.name" clearable @on-enter="search"></Input>
            </FormItem>
          </Col>
          <Col :md="6" :xs="24">
            <FormItem label="参评类别">
              <Select v-model="form.type" clearable @on-change="changeType">
                <Option value="建筑应用创新综合类">建筑应用创新综合类</Option>
                <Option value="建筑应用创新单项类">建筑应用创新单项类</Option>
              </Select>
            </FormItem>
          </Col>
          <Col :md="6" :xs="24" v-if="form.type=='建筑应用创新单项类'">
            <FormItem label="参评类别">
              <Select v-model="form.subType" clearable>
                <Option value="建筑材料应用创新类">建筑材料应用创新类</Option>
                <Option value="装配式建筑应用创新类">装配式建筑应用创新类</Option>
                <Option value="建筑部品部件应用创新类">建筑部品部件应用创新类</Option>
                <Option value="设备设施应用创新类">设备设施应用创新类</Option>
                <Option value="其它应用创新类">其它应用创新类</Option>
              </Select>
            </FormItem>
          </Col>
          <Col :md="6" :xs="24">
            <FormItem label="评审状态：">
              <Select v-model="form.reviewStatus" clearable>
                <Option value="UNSCORED">未评分</Option>
                <Option value="SCORED">已评分</Option>
                <Option value="">全部</Option>
              </Select>
            </FormItem>
          </Col>
          <Col :md="form.type=='建筑应用创新单项类'?24:6" :xs="24">
            <p class="textRight"><Button type="text" @click="reset" class="textBtn">重置查询条件</Button></p>
          </Col>
        </Row>
        <!-- <Row :gutter="30">
          <Col :md="8" :xs="24">
            <FormItem label="参评项目提交日期：">
              <Row :gutter="30">
                <Col span="12">
                  <DatePicker v-model="dateList[0]" format="yyyy-MM-dd" placeholder="选择日期" style="width:100%"></DatePicker>
                </Col>
                <Col span="12">
                  <span class="dateText colorGreen">至</span>
                  <DatePicker v-model="dateList[1]" format="yyyy-MM-dd" placeholder="选择日期" style="width:100%"></DatePicker>
                </Col>
              </Row>
            </FormItem>
          </Col>
        </Row> -->
      </Form>
      <Divider plain><Button class="defaultBtn searchBtn" shape="circle" :loading="loading" @click="search">查询</Button></Divider>
    </div>
    <div class="listWrap">
      <p>共检索到<span class="colorYellow"> {{total}} </span>套作品：</p>
      
      <Row :gutter="15" v-if="!loading">
        <Col class="mgTop" :md="8" :xs="24" v-for="p in list" :key="p.projectInfo.id">
          <div class="item">
            <div class="imgWrap">
              <router-link class="block" :to="`/review/info?id=${p.projectInfo.id}`">
                <img class="img" :src="getImageUrl(p.projectInfo.cover)" @load="gCenterImg" alt="">
              </router-link>
            </div>
            <div class="info">
              <!-- <p class="name"><router-link class="colorGreen" :to="`/review/info?id=${p.id}`">{{p.name||'-'}}</router-link></p>
              <p class="infoItem">参评单位 {{p.regInfo.name}}</p>
              <p>{{p.type||'-'}}<template v-if="p.subType"> - {{p.subType}}</template></p> -->
              <div class="clearfix pdBtm">
                <div class="labelLeft">项目名称：</div>
                <div class="contentRight"><router-link class="colorGreen" :to="`/review/info?id=${p.projectInfo.id}`">{{p.projectInfo.name||'-'}}</router-link></div>
              </div>
              <!-- <div class="clearfix">
                <div class="label">参评单位：</div>
                <div class="content">{{p.regInfo.name}}</div>
              </div> -->
              <div class="clearfix">
                <div class="labelLeft">参评类别：</div>
                <div class="contentRight">{{p.projectInfo.type||'-'}}<template v-if="p.projectInfo.subType"> - {{p.projectInfo.subType}}</template></div>
              </div>
              <div class="status">
                <template v-if="p.status!='SCORED'">
                  <p>评审状态</p>
                  <p class="num">未评分</p>  
                </template>
                <template v-else>
                  <p>{{userInfo.competitionUser.level == "FINAL" ? "终审" : "初审"}}分数</p>
                  <p class="num">{{p.totalScore}}分</p>
                </template>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div v-else class="note">
        <Icon class="loading" type="ios-loading" />
        <p>加载中...</p>
      </div>
      <div class="note" v-if="!loading&&total==0">暂时没有符合筛选条件的项目</div>
      
      <div class="pageWrap">
        <Page v-if="total>0" :total="total" :page-size="12" :current="currentPage" @on-change="changePage"></Page>
      </div>
    </div>
  </div>
</template>

<script>
import Service from '@/api/user'
export default {
  data(){
    return{
      userInfo: this.$store.getters.userInfo || {},
      form: {},
      dateList: [],
      currentPage: 1,
      total: 0,
      list: [],
      loading: false,
      isDisabled: false
    }
  },
  mounted(){
    if (this.gIsLogin()) {
      // if(this.userInfo.competitionUser.pushCompany){
      //   this.form.pushCompany = this.userInfo.competitionUser.pushCompany
      //   this.isDisabled = true
      // }
      this.getList()
      this.getScoreCount()
    } else {
      this.$router.push('/login');
    }
  },
  methods:{
    // 选择参评类别
    changeType(val){
      if(val==undefined || val=='建筑应用创新综合类'){
        this.form.subType = null
      }
    },
    // 重置查询条件
    reset(){
      this.form = {}
      // if(this.userInfo.competitionUser.pushCompany){
      //   this.form.pushCompany = this.userInfo.competitionUser.pushCompany
      //   this.isDisabled = true
      // }
      this.dateList = []
      this.currentPage = 1
      this.getList()
    },
    // 查询
    search(){
      this.currentPage = 1
      this.getList()
    },
    // 换页
    changePage(p) {
      this.currentPage = p
      this.getList()
      let offsetTop = document.getElementsByClassName('listWrap')[0].offsetTop
      document.body.scrollTop = offsetTop
      document.documentElement.scrollTop = offsetTop
    },
    // 获取项目列表
    getList(){
      this.loading = true
      let conditions = this.getConditions()
      Service.getDistributionProjects(conditions)
        .then(res => {
          if (res.errorNo == 0) {
            this.list = res.data;
            if(this.currentPage==1){
              this.total = res.totalSize
            }
            // let reviewing = 0, reviewed = 0;
            // for(let i=0; i<this.list.length; i++){
            //   if(this.list[i].status == "SCORED"){
            //     reviewed ++;
            //   }
            // }
            // reviewing = this.total - reviewed;
            // this.$parent.info.reviewing = reviewing
            // this.$parent.info.reviewed = reviewed
            this.getScoreCount();
          } else {
            this.$Message.error(res.errorMessage)
          }
          this.loading = false
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试")
          this.loading = false
        });
    },
    // 获取已评分项目数
    getScoreCount(){
      Service.getCompetitionScoredCount()
        .then(res => {
          if (res.errorNo == 0) {
            // console.log(res.data)
            // this.$parent.info.reviewed = res.data
            this.$parent.info.reviewing = this.total - res.data;
            this.$parent.info.reviewed = res.data;
          } else {
            this.$Message.error(res.errorMessage)
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试")
        });
    },
    // 筛选条件
    getConditions(){
      let type = null, subType = null
      if(this.form.type){
        if(this.form.type=='建筑应用创新综合类'){
          type = '建筑应用创新综合类'
          subType = null
        }else{
          type = '建筑应用创新单项类'
          subType = this.form.subType
        } 
      }
      
      // let pushCompany = null, pushCode = null
      // if(this.form.pushCompany == "其他"){
      //   pushCompany = null;
      //   pushCode = "无";
      // }else{
      //   pushCompany = this.form.pushCompany;
      //   pushCode = null;
      // }

      // let judgeStatus = null;
      // judgeStatus = this.form.reviewStatus
      // if(this.form.reviewStatus == "SCORED"){
      //   judgeStatus = true;
      // }else if(this.form.reviewStatus == "UNSCORED"){
      //   judgeStatus = false;
      // }
      let projectName = this.form.name;
      if(projectName){
        projectName = projectName.trim();
      }

      return {
        page: this.currentPage - 1,
        size: 12,
        sort: "projectId,desc",
        // "regName.contains": this.form.regName || null,
        // 'applicantType.eq': this.form.applicantType || null,
        // "pushCode.eq" : pushCode,
        // "pushCompany.eq": pushCompany,
        // "checkStatus.eq": this.form.status || null,
        // "checkStatus.ne": "EMPTY",
        // "firstStatus.eq": this.userInfo.competitionUser.level == "FINAL" ? "FINAL" : null,
        // "checkStatus.eq": "SUCCESS",
        // "judgeStatus.eq": judgeStatus,
        "projectName.contains": projectName || null,
        "projectType.eq": type,
        "projectSubType.eq": subType,
        "status.eq": this.form.reviewStatus || null,
        "judgeId.eq": this.userInfo.id,
        "stage.eq": this.userInfo.competitionUser.level
        // "createdDate.gt": this.getFormatDate(this.dateList[0]),
        // "createdDate.lt": this.getFormatDate(this.dateList[1]),
      };
    },
    // 时间转换
    getFormatDate(date){
      if (
        date &&
        typeof date != "string"
      ) {
        let sYear = date.getFullYear() + "";
        let sMonth =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1 + "";
        let sDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate() + "";
        return sYear + "-" + sMonth + "-" + sDate;
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.search{
  padding: 15px 30px;
  .dateText{
    position: absolute;
    left: -8px;
  }
  .textBtn{
    margin-top: 25px;
    padding: 0;
    color: #277a44;
  }
  .searchBtn{
    padding-left: 30px;
    padding-right: 30px;
  }
}
.listWrap{
  padding: 0 30px 50px;
  .loading {
    animation: k-loading 1000ms infinite;
  }
  @keyframes k-loading {
    0 {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .note{
    text-align: center;
    padding: 30px 0;
  }
  .mgTop {
    margin-top: 20px;
  }
  .item {
    box-shadow: 0 1px 2px rgba($color: #000000, $alpha: 0.2);
    // border: 1px solid#707070;
    background: #fff;
    border-radius: 10px;
    height: 100%;
    overflow: hidden;
    position: relative;
    .imgWrap {
      position: relative;
      overflow: hidden;
      .block{
        display: block;
        position: relative;
        padding-bottom: 50%;
        overflow: hidden;
      }
      .img {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .info {
      padding: 20px 85px 20px 10px;
      position: relative;
      color: #949F97;
      line-height: 1.3em;
      // .name{
      //   font-size: 14px;
      //   line-height: 1.5em;
      // }
      // .infoItem{
      //   padding: 15px 0 5px;
      // }
      .pdBtm{
        padding-bottom: 15px;
      }
      .labelLeft {
        float: left;
        width: 70px;
      }
      .contentRight {
        margin-left: 70px;
        color: #777;
      }
      .status{
        position: absolute;
        right: 10px;
        top: 20px;
        width: 60px;
        text-align: center;
        .num{
          font-size: 16px;
          font-weight: bold;
          color: #FB9840;
          padding-top: 10px;
        }
      }
    }
  }
}
@media screen and (max-width:768px) {
  .search{
    padding: 15px;
  }
  .listWrap{
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>